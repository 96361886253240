@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.background-image-container {
    position: relative;
    width: 100vw;
    height: 40vh;
    /* Set the desired height for your background image container */
    background-image: url("/public/media/header.jpg");
    /* Path to your background image */
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 0;
    overflow: hidden;
}

.product-card-page {
    /* position: relative; */
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    justify-content: center;
    /* background-image: url("/public/media/header1.png"); */
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 0;
    font-family: 'Montserrat Alternates', sans-serif;
}

.product-card {
    flex: 1 1 auto;
    /* Allows cards to grow and shrink */
    text-align: center;
    margin-top: 10px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    height: 80px;
    background-color: #0f0c04d8;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: transform 0.3s, box-shadow 0.3s, filter 0.3s;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.322);
    cursor: pointer;
    border-radius: 8px;
    font-family: 'Montserrat Alternates', sans-serif;
    font-weight: bold;
    color: white;
    font-size: 1.0em;

    &:hover,
    &.selected,
    &:active {
        box-shadow: 0 0 12px rgba(0, 0, 0, 0.623);
        filter: brightness(110%);
    }

}

.product-card.selected {
    transform: translateY(-15px) scale(1.05);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    background-color: rgba(255, 255, 255, 0.884);
    color: black;
}



.additional-cards-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 5px;
}

.additional-card {
    /* color: #f0d437; */
    text-align: center;
    margin-bottom: 5px;
    height: 80px;
    width: 100%;
    background-color: #1b1b1bdc;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    border-radius: 8px;
    font-family: 'Montserrat Alternates', sans-serif;
    font-weight: bold;

    font-size: 1.0em;

}

.additional-card p {
    margin: 6px 0;
    text-align: left;
    margin-left: 5px;
    color: white;
    /* font-size: 0.8em; */
    /* Adjust the margin between paragraphs as needed */
}

.additional-card .content {
    color: #bdb7a0;
    /* Change the color for content */
    font-size: 0.8em;
    text-align: left;
    margin-left: 5px;
    /* Adjust the font size for content */
}

.additional-card .price {
    color: #f0d437;
    font-size: 1.1em;
    font-weight: bold;
    text-align: right;
    /* Align the price to the right */
    margin-top: auto;
    margin-right: 10px;
    /* Push the price to the bottom */
}

.additional-card .flex-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}