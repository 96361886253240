@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 400px;
    margin: 0;
    padding: 20px;
    border-radius: 8px;
    background-color: white;
    /* Diğer stiller */
}

body {
    display: flex;
    justify-content: center;
    /* Yatayda ortala */
    align-items: center;
    /* Dikeyde ortala */
    min-height: 100vh;
    /* Tam ekran yüksekliği */
    /* background-image: url('/public/media/background.jpg'); Yolu doğru ayarlayın */
    background-size: cover;
    /* Resmi kapsama */
    background-position: center;
    /* Resmi ortalama */
    background-repeat: no-repeat;
    /* Tekrar etmemesi için */
    margin: 0;
    /* Varsayılan margin'leri kaldır */
}

h2 {
    color: #1d0b0b;
    font-family: 'Montserrat Alternates', sans-serif;
    font-weight: 800;
    margin-top: 100px;
    /* margin-bottom: 1%; */
    /* H2 etiketine eklenen margin-bottom */
}

p {
    color: #666;
    margin-bottom: 10px;
}

.google-login-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #957f4d8b;
    color: #fff;
    border: none;
    padding: 45px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 300px;
    height: 150px;
    font-family: 'Montserrat Alternates', sans-serif;
    margin-top: 80px;
    /* Google giriş butonunun margin-top değeri azaltıldı */
}

.google-login-btn:hover {
    background-color: #2d2b2a;
}

.google-login-btn img {
    margin-right: 10px;
}

.app-logo {
    border-radius: 10px;
}