.footer {
    background-color: var(--black-color);
    padding: 2rem 0 2.5rem;
    /* background-image: url("/public/media/314.jpg"); */
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: -1;
}

.footer__title {
    text-align: center;
    color: #fff;
    font-size: var(--biggest-font-size);
    margin-bottom: 10px;
}

.footer__description {
    margin-bottom: 2rem;
    text-align: center;
}

.footer__title span {
    color: var(--first-color);
}

/* .footer__newsletter-description {
  margin-bottom: 0.75rem;
}

.footer__newsletter {
  display: flex;
  column-gap: 0.5rem;
} */

.footer__content.grid {
    display: flex;
    justify-content: space-around;
    /* Yatay (horizontal) ortalama için */
    align-items: center;
    /* Dikey (vertical) ortalama için */
}

.footer__data {
    text-align: center;
    /* Yazıları yatayda ortalamak için */
}

.footer__keywords h6 {
    color: rgba(0, 0, 0, 0.2); /* Siyah ama %80 şeffaf */
}


.footer__map-link {
    color: var(--text-color);
    /* Adjust the color as needed */
    text-decoration: underline;
    /* Underline to indicate it's a link */
    cursor: pointer;
    /* Show pointer cursor on hover */
}


.footer__input {
    border: none;
    outline: none;
    padding: 1.25rem 1rem;
    border-radius: 0.25rem;
    width: 70%;
    color: var(--first-color);
}

.footer__button {
    padding: 0.75rem;
    font-size: 2rem;
    border-radius: 0.25rem;
    display: inline-flex;
    background-color: var(--first-color);
}

.footer__button i {
    transform: rotate(-30deg);
    transition: 0.4s;
    color: #fff;
}

.footer__button:hover i {
    transform: rotate(-30deg) translate(0.25rem);
}

.footer__content {
    row-gap: 2.5rem;
    padding-bottom: 2.5rem;
    border-bottom: 1px solid var(--text-color);
}

.footer__subtitle {
    color: #fff;
    font-size: var(--h2-font-size);
    margin-bottom: 0.75rem;
}

.footer__flag {
    width: 1rem;
}

.footer__group {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 1.5rem;
}

.footer__social {
    /* display: flex; */
    /* justify-content: left; */
    column-gap: 1.5rem;
    font-size: 1.25rem;
    margin-bottom: 2rem;
    text-align: center;
}

.footer__social-link {
    color: #fff;
    transition: 0.4s;
}

.footer__social-link:hover {
    color: var(--first-color);
}

.footer__copy {
    font-size: var(--small-font-size);
}

@media screen and (max-width: 768px) {
    .footer__content.grid {
        flex-direction: column;
        align-items: center;
    }

    .footer__data {
        text-align: center;
        margin-bottom: 1rem;
    }
}

/* end */

/*=============== BREAKPOINTS ===============*/
/* For small devices */
@media screen and (max-width: 320px) {
    .container {
        margin-inline: 1rem;
    }

    .place__img-smalls {
        display: none;
    }
}

/* For medium devices */
@media screen and (min-width: 576px) {
    .special__category {
        grid-template-columns: repeat(2, 200px);
        column-gap: 3rem;
    }

    .products__content {
        grid-template-columns: repeat(2, 200px);
        padding-bottom: 3rem;
    }

    .blog__content {
        grid-template-columns: 450px;
        justify-content: center;
    }

    .footer__newsletter {
        width: 350px;
    }
}

/* For large devices */
@media screen and (min-width: 769px) {
    .section {
        padding: 7rem 0 2rem;
    }

    .nav {
        height: calc(var(--header-height) + 1.5rem);
    }

    .nav__toggle,
    .nav__close {
        display: none;
    }

    .nav__list {
        padding-top: 0;
        flex-direction: initial;
        column-gap: 4rem;
        font-family: 'Montserrat Alternates', sans-serif;
    }

    .nav__link {
        position: relative;
        font-size: var(--normal-font-size);
        text-transform: initial;
    }

    .products__filters {
        grid-template-columns: repeat(3, 200px);
    }

    .place__content,
    .footer__content {
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
    }

    .place__data {
        text-align: initial;
    }
}

@media screen and (min-width: 1024px) {
    .container {
        margin-inline: auto;
    }

    .place__content {
        column-gap: 6rem;
    }

    .place__images {
        margin-top: 2rem;
    }

    .place__img-big {
        width: 428px;
    }

    .place__img-smalls {
        /* width: 153px; */
        right: -35px;
    }

    .place__title {
        margin-bottom: 1rem;
    }

    .footer__content {
        grid-template-columns: repeat(4, max-content);
        column-gap: 4rem;
        padding-bottom: 5.5rem;
    }

    .footer__group {
        margin-top: 3rem;
        flex-direction: row;
        justify-content: space-between;
    }

    .footer__social {
        column-gap: 2rem;
    }

    .footer__social-link {
        font-size: 1.5rem;
    }

}