@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.admin_header {
    background-color: #000;
    /* Siyah arka plan */
    color: #fff;
    /* Beyaz metin rengi */
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    display: flex;

}

.admin_logo {
    display: flex;
    align-items: center;
    font-weight: 700;
    padding-top: 3px;
    border-radius: 10px;
    max-width: 100px;
    height: auto;
}

.table-container {
    max-height: 800px;
    /* ya da istediğiniz bir değer */
    overflow-y: auto;
}


table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    font-family: 'Montserrat Alternates', sans-serif;
}

thead {
    background-color: #333030;
    position: sticky;
    top: 0;
}

th,
td {
    padding: 12px;
    text-align: left;
    /* border-bottom: 1px solid #dddddd62; */
}

th {
    background-color: #eb642fa4;
    color: white;
}


.admin_category {
    color: #3d3d3b;
    font-weight: bold;
    font-size: 1.1em;
}

.price-cell {
    color: black;
    font-weight: bold;
    font-size: 1.1em;

}


tr:nth-child(odd) {
    background-color: #bfbfa885;
}

tr:hover {
    background-color: #f5f5f5;
}

.sign-out-button {
    background-color: #2c2108c5;
    color: #fff;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: 'Montserrat Alternates', sans-serif;
    height: 50px;
    margin-left: 5px;
}

.sign-out-button:hover {
    background-color: whitesmoke;
}

.admin-button {
    background-color: #2c2108c5;
    color: #fff;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: 'Montserrat Alternates', sans-serif;
    height: 50px;
}

.admin-button:hover {
    background-color: whitesmoke;
}

.save-button {
    background-color: #4caf50;
    /* Yeşil renk */
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    margin-right: 5px;
    font-family: 'Montserrat Alternates', sans-serif;
    /* Düğmeler arasında bir boşluk bırakmak için */
}

.save-button:hover {
    background-color: #45a049;
    /* Yeşil renk (hover durumunda) */
}

/* Edit düğmesi stil tanımlamaları */
.edit-button {
    background-color: #ff9800;
    /* Turuncu renk */
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    margin: 5px;
    font-family: 'Montserrat Alternates', sans-serif;
    width: 75px;
}

.edit-button:hover {
    background-color: #e65c00;

    /* Turuncu renk (hover durumunda) */
}

.action-buttons {
    display: flex;
    /* flex-wrap: wrap; */
    width: 100%;
}

.delete-button {
    background-color: #d8220ad4;
    /* Turuncu renk */
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    margin: 5px;
    font-family: 'Montserrat Alternates', sans-serif;
    width: 75px;
}

.cancel-button {
    background-color: #889396d4;
    /* Turuncu renk */
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    margin: 5px;
    font-family: 'Montserrat Alternates', sans-serif;
    width: 75px;
    height: 60px;

}

.delete-button:hover {
    background-color: #e60f00;

}

@media screen and (max-width: 600px) {
    thead {
        display: none;
    }

    th,


    .edit-button {
        float: right;
        padding: 8px 12px;
        margin-bottom: 4px;
    }

    .delete-button {
        float: right;
        padding: 8px 12px;
        margin-bottom: 4px;
    }

    /* Mobil cihazlar için */
    table {
        width: 100%;
    }

    /* Input kutularını içeren td elemanlarının genişliği */
    table td {
        display: flex;
        width: 100%;
        box-sizing: border-box;
    }

    /* Butonların genişliği ve hizalama */
    .save-button,
    .cancel-button,
    .edit-button,
    .delete-button {
        width: 100%;
    }

    .save-button {
        float: right;
        /* padding: 8px 12px;
        margin-bottom: 4px; */
    }

    .cancel-button {
        float: right;
        /* padding: 8px 12px;
        margin-bottom: 4px; */
    }

    .new-row,
    .admin_category {
        display: flex;
        flex-wrap: wrap;
        padding: 8px 12px;
    }

    .input-field {
        flex: 0 0 100%;
        font-size: 14px;
        touch-action: auto;
        -ms-touch-action: auto;
    }

}

.input-field {
    font-family: 'Montserrat Alternates', sans-serif;
    font-size: 14px;
    width: 300px;
    height: 30px;
    touch-action: manipulation;
    -ms-touch-action: manipulation;
    max-width: 100%;
    margin-right: 5px;
}

.sorting-dropdown {
    display: flex;
    align-items: center;
    margin-left: auto;
    border-radius: 5px;
    font-family: 'Montserrat Alternates', sans-serif;
    margin-right: 10px;

}

.select-font {
    font-family: 'Montserrat Alternates', sans-serif;
}

.category-input {
    width: 100px;
    /* Adjust the width as needed */
}

.priority-input {
    width: 60px;
    /* Adjust the width as needed */
}

.price-input {
    width: 60px;
    /* Adjust the width as needed */
}

.dropdown-space {
    margin-right: 10px;

}

.new-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: 5px;
    margin-top: 5px;
    /* Allow items to wrap to the next line */
}

.input-field,
.price-input,
.priority-input {
    /* flex: 1; */
    margin-bottom: 4px;
}

.save-button,
.cancel-button {
    margin-top: 5px;
    height: 80%;
    margin-left: 10px;
    margin-bottom: 4px;

}