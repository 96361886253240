.hero {
    position: relative;
    width: 100%;
    height: 100vh;
    /* Adjust to your desired height */
    overflow: hidden;
    margin: 0;
    /* Ensure no margin */
    padding: 0;
    /* Ensure no padding */
}

.hero__video {
    object-fit: cover;
    width: 100%;
    height: 100vh;
    /* Set to full viewport height */
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.2);

}

.hero__image {
    max-width: 100%;
    height: auto;
    width: 450px;
    z-index: 1;
}

.hero__button {
    position: absolute;
    bottom: -140px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #D7B56D;
    padding: 1rem 1.75rem;
    border-radius: 0.25rem;
    color: #fff;
    text-transform: uppercase;
    transition: 0.3s;
    font-weight: bold;
    font-size: 15px;
}

.hero__button:hover {
    background-color: var(--first-color-alt);
}

/* Add media query for smaller screens if necessary */
@media screen and (max-width: 767px) {
    .hero__video {
        object-position: right;
        /* Adjust the value as needed */
    }
}

.hero__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    /* Adjust the opacity and color as needed */
    margin: 0;
    /* Ensure no margin */
    padding: 0;
    /* Ensure no padding */
    pointer-events: none;
    /* Allows clicks to pass through */
}