/*=============== GOOGLE FONTS ===============*/
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alumni+Sans+Inline+One:ital@0;1&family=Jacques+Francois+Shadow&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

/*=============== VARIABLES CSS ===============*/
:root {
  --header-height: 5rem;

  /*========== Colors ==========*/
  --first-color: hsl(231, 49%, 41%);
  --first-color-alt: #c9c4bc;
  --black-color: hsl(206, 12%, 12%);
  --card-color: hsl(0, 0%, 90%);
  --title-color: hsl(206, 12%, 12%);
  --text-color: hsl(206, 4%, 42%);
  --text-color-light: hsl(206, 4%, 75%);
  --body-color: white;
  --container-color: #fff;

  /*========== Font and typography ==========*/
  --body-font: 'Lato', sans-serif;
  --biggest-font-size: 2.25rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
}

@media screen and (min-width: 968px) {
  :root {
    --biggest-font-size: 3.5rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
  }
}

/*=============== BASE ===============*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  background-color: var(--body-color);
  color: var(--text-color);
  font-weight: 400;
  /* background-color: #d6a451; */
  background-color: white;
}

h1,
h2,
h3 {
  color: var(--title-color);
  font-weight: var(--font-bold);
}

h1,
h2,
h3,
p {
  line-height: 140%;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
}

/*=============== REUSABLE CSS CLASSES ===============*/
.d-none {
  display: none;
}

.container {
  max-width: 1024px;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.grid {
  display: grid;
  gap: 1.5rem;
}

.section {
  padding: 4.5rem 0 2rem;
}

.section__title {
  position: relative;
  font-size: var(--h1-font-size);
  margin-bottom: 2.5rem;
  font-weight: 700;
}

.section__title::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 25%;
  height: 4px;
  background-color: var(--first-color);
}

.header {
  width: 100%;
  background-color: hsla(206, 12%, 12%, 1);
  /* Change to the desired background color */
  box-shadow: 0 1px 4px hsla(0, 4%, 15%, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
}

.nav {
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav__logo,
.nav__toggle {
  color: #fff;
}

.nav__logo {
  display: flex;
  align-items: center;
  font-weight: 700;
  padding-top: 3px;
  /* or margin-top: 10px; or adjust the value as needed */
  border-radius: 10px;
  max-width: 100px;
  /* İstenilen genişlik değerini belirtin */
  height: auto;
}

.hide-logo {
  display: none;
}

.show-logo {
  display: block;
}

.nav__logo span {
  color: var(--first-color);
}

.nav__toggle {
  display: flex;
  font-size: 1.15rem;
  cursor: pointer;
  margin-top: 0px;
  margin-right: 10px;
}

/* Add this CSS rule for hiding the mobile menu */
@media screen and (max-width: 768px) {
  .nav__menu {
    /* display: none; */
    position: fixed;
    top: 0;
    right: -100%;
    /* Initially off-screen */
    width: 60%;
    height: 100%;
    background-color: #111111d8;
    z-index: 1000;
    transition: 0.5s;
  }

  .nav__toggle {
    display: flex;
    font-size: 1.15rem;
    cursor: pointer;
    margin-top: 0px;
    margin-right: 10px;
  }

  .show-menu {
    right: 0;
    width: 60%;
  }
}

.nav__list {
  display: flex;
  flex-direction: column;
  text-align: center;
  row-gap: 3rem;
  padding-top: 9rem;
  list-style-type: none;
  margin: 0;
  font-family: 'Montserrat Alternates', sans-serif;
}

.nav__contact-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.footer__social {
    
    display: flex; /* Sosyal medya ikonlarını yan yana dizmek için */
    align-items: center; /* İkonu dikeyde ortalar */
    list-style-type: none; /* Varsayılan liste işaretlerini kaldırır */
    margin: 0; /* Varsayılan margin'i sıfırlar */
    padding: 0; /* Varsayılan padding'i sıfırlar */
}

.footer__social-link i {
    font-size: 2rem; /* İstediğiniz büyüklüğe göre ayarlayın, örneğin 2rem */
    color: #D7B56D; /* Rengi değiştirmek isterseniz */ 
    padding-top: 32px;
}


.nav__contact {
    display: flex;
    align-items: center; /* Telefon simgesini ve metni hizalar */
    color: #fff; /* Metin rengi */
    text-decoration: none; /* Alt çizgiyi kaldır */
    font-weight: bold; /* Kalın yazı tipi */
    font-size: 1.0rem; /* Yazı boyutu */
}

.nav__contact svg {
    font-size: 1.5rem; /* Telefon ikonunun boyutunu ayarlayın */
    color: #D7B56D; /* İkonun rengini ayarlayın */
    margin-right: 0.5rem; /* İkon ile metin arasındaki boşluk */
}

.nav__link {
  text-transform: uppercase;
  color: var(--text-color-light);
  font-size: var(--h2-font-size);
  transition: 0.3s;
  font-weight: 700;
}

.nav__link.active-link {
  color: #fff;
}

.nav__link:hover {
  color: #fff;
}

.nav__close {
  font-size: 2rem;
  color: #fff;
  position: absolute;
  top: 1rem;
  right: 2rem;
  cursor: pointer;
}

/* Show menu */
.show-menu {
  right: 0;
  width: 60%;
}

/* hero style */
.hero {
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

/* special style */
.special__category {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: 200px;
  justify-content: center;
  row-gap: 2.5rem;
}

.special__img__border {
  width: 80px;
  height: 80px;
  margin-bottom: 1rem;
  transition: 0.4s;
  border: 3px solid var(--first-color);
  border-radius: 100%;
  padding: 1.25rem;
}

.special__title {
  font-size: var(--h2-font-size);
  margin-bottom: 0.25rem;
  font-weight: 700;
}

/* end */

products style .products__filters {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.5rem 2rem;
  margin-bottom: 3.5rem;
}

.products__item {
  cursor: pointer;
}

.products__title {
  font-size: var(--h3-font-size);
  color: var(--text-color);
  margin-bottom: 0.5rem;
  font-weight: 700;
}

.active-product .products__title {
  color: var(--title-color);
}

.products__stock {
  font-size: var(--small-font-size);
}

.products__content {
  grid-template-columns: 200px;
  justify-content: center;
}

.products__card {
  background-color: var(--container-color);
  border-radius: 0.75rem;
}

.products__img {
  border-radius: 0.75rem 0.75rem 0 0;
}

.products__data {
  padding: 1rem 0 1.25rem 1rem;
  position: relative;
}

.products__price {
  font-size: var(--h2-font-size);
  font-weight: 700;
}

.products__name {
  font-size: var(--normal-font-size);
}

.products__button {
  border-radius: 50%;
  padding: 0.625rem;
  display: inline-flex;
  font-size: 1.25rem;
  position: absolute;
  right: 1rem;
  top: -1.25rem;
  box-shadow: 0 4px 12px hsla(0, 88%, 62%, 0.2);
  background-color: var(--first-color);
  color: #fff;
}

/* end */

/* place style */
.place {
  background-color: #fff;
  padding: 4rem 0;
}

.place-content {
  row-gap: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.place__images {
  position: relative;
  justify-self: center;
  margin-top: 2rem;
}

.place__img-big,
.place__img-small {
  border-radius: 0.5rem;
}

.place__img-big {
  width: 100%;
  /* Önceki 400px'yi 100% olarak değiştirdik */
  max-width: 600px;
  /* İsteğe bağlı, en fazla genişlik */
  height: auto;
  /* Genişlik oranına bağlı olarak yükseklik ayarla */
}

.place__img-smalls {
  width: 80px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -20px;
  margin: auto 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  row-gap: 0.75rem;
}

.place__img-small.active {
  border: 3px solid var(--first-color);
}

.place__data {
  text-align: center;
}